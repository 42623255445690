import { Box, Button, Stack, useColorModeValue } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useLocation } from "react-router-dom";
import {
  renderThumbDark,
  renderThumbLight,
  renderTrack,
  renderView,
} from "../../components/Scrollbar/Scrollbar";
import { HSeparator } from "../../components/Separator/Separator";
import { routes as routerRoutes } from "../../router";
import SidebarLink from "./SidebarLink";

interface Props {
  routes: typeof routerRoutes;
  logo: any;
}
export default function Sidebar({ logo, routes }: Props) {
  const location = useLocation();
  const mainPanel = React.useRef();
  const variantChange = "0.2s linear";

  const activeRoute = (routeName: string) => {
    return location.pathname === routeName ? "active" : "";
  };

  const auth = getAuth();

  function handleSignOutButtonClick() {
    auth.signOut();
    window.location.href = "/";
  }

  const sidebarBg = useColorModeValue("white", "navy.800");
  const sidebarRadius = "20px";
  const sidebarMargins = "0px";
  var brand = (
    <Box pt={"25px"} mb="12px">
      {logo}
      <HSeparator my="26px" />
    </Box>
  );

  return (
    <Box ref={mainPanel as any}>
      <Box display={{ sm: "none", lg: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          ms={{
            sm: "16px",
          }}
          my={{
            sm: "16px",
          }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          m={sidebarMargins}
          filter="drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))"
          borderRadius={sidebarRadius}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={useColorModeValue(
              renderThumbLight,
              renderThumbDark
            )}
            renderView={renderView}
          >
            <Box>{brand}</Box>
            <Stack direction="column" mb="40px">
              <Box>
                {routes.map((route: any, key: any) => (
                  <SidebarLink
                    route={route}
                    isActive
                    key={key}
                    variantChange={variantChange}
                  />
                ))}
              </Box>
              <p>Hey,</p>
              <p data-test-id="current-user-email" style={{ color: "#666" }}>
                {auth.currentUser?.email}
              </p>
              <Button onClick={handleSignOutButtonClick}>Sign out</Button>
            </Stack>
          </Scrollbars>
        </Box>
      </Box>
    </Box>
  );
}
