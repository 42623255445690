import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { AskValue } from "core";
import React from "react";
import { WithId } from "../../../types/utils";

interface Props {
  ask: WithId<AskValue>;
  isLast?: boolean;
}

export const columnNames = ["ID", "Ask", "Tags"];

export default function AskRow({ ask, isLast }: Props) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {ask.id}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {ask.text}
          </Text>
        </Flex>
      </Td>

      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {ask.tags?.join(", ")}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}
