import {
  Avatar,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { ResponseValue } from "core";
import { truncate } from "lodash";
import React from "react";
import { FaLink, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import { WithId } from "../../../types/utils";

interface Props {
  response: any | WithId<ResponseValue>;
  isLast?: boolean;
}

export const columnNames = [
  "Name",
  "Email",
  "LinkedIn",
  "Can help with",
  "Help wanted",
  "Status",
];

export default function ResponseRow({ response, isLast }: Props) {
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <>
      <Tr>
        <Td
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : undefined}
        >
          <Link
            to={`/communities/${response.communityId}/responses/${response.id}`}
          >
            <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
              <Avatar
                src={response.linkedinProfile?.profile_pic_url}
                name={response.name}
                borderRadius="12px"
                me="16px"
              />
              <Text
                fontSize="md"
                color={textColor}
                fontWeight="bold"
                pb=".5rem"
              >
                {response.name}
              </Text>
            </Flex>
          </Link>
        </Td>
        <Td
          minWidth={{ sm: "200px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : undefined}
        >
          <Flex align="center" py=".8rem" flexWrap="nowrap">
            <Link
              to={`/communities/${response.communityId}/responses/${response.id}`}
            >
              <Text fontSize="sm" color="gray.700" fontWeight="normal">
                {response.email}
              </Text>
            </Link>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "200px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : undefined}
        >
          {response.linkedinProfileUrl ? (
            <a href={response.linkedinProfileUrl}>
              <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
                <FaLinkedin />
                <FaLink />
              </Flex>
            </a>
          ) : null}
        </Td>
        <Td
          minWidth={{ sm: "200px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : undefined}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {truncate(response.canOffer, { length: 500 })}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "200px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : undefined}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {truncate(response.helpWanted, { length: 500 })}
            </Text>
          </Flex>
        </Td>
        <Td
          minWidth={{ sm: "200px" }}
          pl="0px"
          borderColor={borderColor}
          borderBottom={isLast ? "none" : undefined}
        >
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {response.finalizedAt ? "Finalized" : "Pending"}
            </Text>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
