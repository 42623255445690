import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { MailValue } from "core";
import React from "react";
import { Link } from "react-router-dom";
import { WithId } from "../../../types/utils";
import { formatDate } from "../../../utils/dates";

interface Props {
  mail: WithId<MailValue<any>>;
  isLast?: boolean;
}

export const columnNames = [
  "Subject",
  "Sent at",
  "Delivered at",
  "Opened at",
  "Clicked at",
];

export default function MailRow({ mail, isLast }: Props) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Link to={`/mail/${mail.id}`}>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {mail.subject}
            </Text>
          </Flex>
        </Link>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {formatDate(mail.delivery.startTime.toDate())}
          </Text>
        </Flex>
      </Td>

      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {formatDate(mail.eventsSummary?.delivered?.toDate())}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {formatDate(mail.eventsSummary?.opened?.toDate())}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {formatDate(mail.eventsSummary?.clicked?.toDate())}
          </Text>
        </Flex>
      </Td>
    </Tr>
  );
}
