// TODO: we may like to migrate this code into a core impulse repository (as it will be shared
// between all apps that use firebase auth)
export function parseError(errorCode: string | undefined) {
  switch (errorCode) {
    case "auth/email-already-in-use":
      return {
        email:
          "It looks like that email is already in use. If you have an account, sign in using the link at the bottom.",
      };
    case "auth/invalid-email":
    case "auth/user-not-found":
      return {
        email: "We can't find an account for that email address",
      };
    case "auth/wrong-password":
      return {
        password: "is incorrect",
      };
    case "auth/weak-password":
      return { password: "This password is too weak" };
    default:
      return { email: "Something went wrong" };
  }
}
