import React from "react";
import { createBrowserRouter, RouteObject } from "react-router-dom";
import { ClockIcon, DocumentIcon, HomeIcon } from "./components/Icons/Icons";
import Dashboard from "./layouts/Admin";
import Communities from "./views/Pages/Communities";
import Community from "./views/Pages/Community";
import { Mail, Mails } from "./views/Pages/Mail";
import Response from "./views/Pages/Response";
import Run from "./views/Pages/Run";
import SignIn from "./views/Pages/SignIn";
import SignOut from "./views/Pages/SignOut";

type Route = Omit<RouteObject, "children"> & {
  label?: string;
  icon?: any;
  requiredClaims?: Array<string>;
  showInSidebar?: boolean;
  children?: Array<Route>;
};
export const routes: Array<Route> = [
  {
    path: "/",
    label: "Dashboard",
    element: <Dashboard />,
    icon: <HomeIcon />,
    requiredClaims: ["admin"],
    children: [
      { path: "/", element: <div>Coming soon: data dashboard</div> },
      {
        path: "/mail",
        label: "Mail",
        showInSidebar: false,
        element: <Mails />,
        children: [
          {
            path: "/mail/:id",
            label: "Mail",
            icon: <DocumentIcon />,
            element: <Mail />,
          },
        ],
      },
      {
        path: "/communities",
        label: "Communities",
        showInSidebar: true,
        icon: <DocumentIcon />,
        element: <Communities />,
        children: [
          {
            path: "/communities/:id",
            label: "Communities",
            icon: <DocumentIcon />,
            element: <Community />,
            children: [
              {
                path: "/communities/:id/runs/:rId",
                label: "Communities",
                icon: <DocumentIcon />,
                element: <Run />,
              },
              {
                path: "/communities/:id/responses/:rId",
                label: "Communities",
                icon: <DocumentIcon />,
                element: <Response />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/signin",
    element: <SignIn />,
  },
  {
    path: "/signout",
    label: "Sign Out",
    icon: <ClockIcon />,
    element: <SignOut />,
  },
];
const router = createBrowserRouter(routes as Array<RouteObject>);

export default router;
