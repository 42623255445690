import { Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import { AssessmentValue, ResponseValue } from "core";
import React from "react";
import { Link } from "react-router-dom";
import { WithId } from "../../../types/utils";

interface Props {
  assessment: WithId<AssessmentValue>;
  seekerResponse?: WithId<ResponseValue>;
  providerResponse?: WithId<ResponseValue>;
  isLast?: boolean;
}

export const columnNames = [
  "ID",
  "Seeker",
  "Provider",
  "Score",
  "Explanation",
  "Intro Requested",
];

export default function AssessmentRow({
  assessment,
  seekerResponse,
  providerResponse,
  isLast,
}: Props) {
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Text fontSize="sm" color="gray.700" fontWeight="normal">
          {assessment.id}
        </Text>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Link
          to={`/communities/${seekerResponse?.communityId}/responses/${seekerResponse?.id}`}
        >
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {seekerResponse?.name}
          </Text>
        </Link>
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
        pl="0px"
      >
        <Link
          to={`/communities/${seekerResponse?.communityId}/responses/${providerResponse?.id}`}
        >
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {providerResponse?.name}
          </Text>
        </Link>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Text fontSize="sm" color="gray.700" fontWeight="normal">
          {assessment.score}
        </Text>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Text fontSize="sm" color="gray.700" fontWeight="normal">
          {assessment.explanation}
        </Text>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Text fontSize="sm" color="gray.700" fontWeight="normal">
          {assessment.introRequestedAt ? "Yes" : "No"}
        </Text>
      </Td>
    </Tr>
  );
}
