import {
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { ComponentProps } from "react";
import Card from "../Card/Card";
import CardBody from "../Card/CardBody";
import CardHeader from "../Card/CardHeader";

type Props = ComponentProps<typeof Flex> & {
  children: React.ReactNode;
  title: string;
  controls?: React.ReactNode;
  action?: React.ReactNode;
  columnNames: Array<string>;
};

export default function TableCard({
  title,
  children,
  columnNames,
  controls,
  action,
  ...rest
}: Props) {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Flex direction="column" {...rest}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Flex alignItems="center" direction="row">
            <Text
              flexGrow={1}
              fontSize="xl"
              color={textColor}
              fontWeight="bold"
            >
              {title}
            </Text>
            {action}
          </Flex>
        </CardHeader>
        <CardBody>
          {controls}
          <Table variant="simple" color={textColor}>
            <Thead>
              <Tr my=".8rem" pl="0px" color="gray.400">
                {columnNames.map((name) => (
                  <Th pl="0px" borderColor={borderColor} color="gray.400">
                    {name}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>{children}</Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}
