import { collection, orderBy, query } from "firebase/firestore";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAuth, useFirestore, useFirestoreCollectionData } from "reactfire";
import TableCard from "../../../components/TableCard";
import CommunityRow, { columnNames } from "./CommunityRow";

export default function CommunitiesIndex() {
  let location = useLocation();
  const isIndex = location.pathname === "/communities";
  if (isIndex) return <Communities />;
  return <Outlet />;
}

function Communities() {
  const firestore = useFirestore();
  const auth = useAuth();
  const collectionsQuery = query(
    collection(firestore, "communities"),
    orderBy("createdAt", "desc")
  );
  const { data = [] } = useFirestoreCollectionData(collectionsQuery, {
    idField: "id",
  });

  return (
    <div>
      <TableCard columnNames={columnNames} title="Communities">
        {data.map((community, index) => (
          <CommunityRow
            community={community}
            isLast={index === data.length - 1}
          />
        ))}
      </TableCard>
    </div>
  );
}
