const firebaseConfig = {
  apiKey: "AIzaSyBNx5GAeHFcx37OfhNVvfAXaYY95Xaosgs",
  authDomain: "intronet-88bc5.firebaseapp.com",
  projectId: "intronet-88bc5",
  storageBucket: "intronet-88bc5.appspot.com",
  messagingSenderId: "802554005530",
  appId: "1:802554005530:web:97ccae62f2529bf911e9a3",
  measurementId: "G-N9673V7QCS",
};

export default firebaseConfig;
