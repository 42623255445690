import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "reactfire";

export default function SignOut() {
  const auth = useAuth();
  const [user, setUser] = useState(null as any);

  useEffect(() => {
    auth.onAuthStateChanged((user) => setUser(user));
    auth.signOut();
  }, []);

  if (!user) return <Navigate to="/signin" />;

  return null;
}
