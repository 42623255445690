import { AssessmentValue, ResponseValue } from "core";
import React from "react";
import TableCard from "../../../../components/TableCard";
import { WithId } from "../../../../types/utils";
import AssessmentRow, {
  columnNames as assessmentColumnNames,
} from "../AssessmentRow";

interface Props {
  assessments: Array<WithId<AssessmentValue>>;
  responsesById: Record<string, WithId<ResponseValue>>;
  isActionDisabled: boolean;
}
export default function AssessmentsTab({
  assessments,
  responsesById,
  isActionDisabled,
}: Props) {
  return (
    <TableCard columnNames={assessmentColumnNames} title="Assessments">
      {assessments.map((assessment) => (
        <AssessmentRow
          providerResponse={responsesById[assessment.providerResponseId]}
          assessment={assessment}
          isLast={assessment.id === assessments[assessments.length - 1].id}
          isActionDisabled={isActionDisabled}
        />
      ))}
    </TableCard>
  );
}
