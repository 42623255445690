import { AssessmentValue, ResponseValue } from "core";
import { collection, Query, query, where } from "firebase/firestore";
import { keyBy, partition, sortBy } from "lodash";
import React from "react";
import { useParams } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import TableCard from "../../../components/TableCard";
import { WithId } from "../../../types/utils";
import AssessmentRow, { columnNames } from "./AssessmentRow";

export default function Run() {
  // Get the selected assessments
  const { id, rId } = useParams();
  const firestore = useFirestore();
  const assessmentsQuery = query(
    collection(firestore, "assessments"),
    where("runId", "==", rId)
  ) as Query<WithId<AssessmentValue>>;
  const { data: assessments = [] } = useFirestoreCollectionData(
    assessmentsQuery,
    { idField: "id" }
  );

  const responsesQuery = query(
    collection(firestore, "responses"),
    where("communityId", "==", id)
  ) as Query<WithId<ResponseValue>>;
  const { data: responses = [] } = useFirestoreCollectionData(responsesQuery, {
    idField: "id",
  });
  const responsesById = keyBy(responses, "id");

  const [selectedAssessments, otherAssessments] = partition(
    assessments,
    (doc) => doc.isSelected
  );

  const sortedAssessments = sortBy(selectedAssessments, (doc) => {
    const seekerName = responsesById[doc.seekerResponseId]?.name;
    const score = 1 - doc.score;
    return [seekerName, score];
  });

  const sortedOtherAssessments = sortBy(otherAssessments, (doc) => {
    const seekerName = responsesById[doc.seekerResponseId]?.name;
    const score = 1 - doc.score;
    return [seekerName, score];
  });

  return (
    <div>
      <TableCard mb={4} columnNames={columnNames} title="Selected Assessments">
        {sortedAssessments.map((assessment) => (
          <AssessmentRow
            assessment={assessment}
            providerResponse={responsesById[assessment.providerResponseId]}
            seekerResponse={responsesById[assessment.seekerResponseId]}
            isLast
          />
        ))}
      </TableCard>

      <TableCard columnNames={columnNames} title="Other Assessments">
        {sortedOtherAssessments.map((assessment) => (
          <AssessmentRow
            assessment={assessment}
            providerResponse={responsesById[assessment.providerResponseId]}
            seekerResponse={responsesById[assessment.seekerResponseId]}
            isLast
          />
        ))}
      </TableCard>
    </div>
  );
}
