import { Box } from "@chakra-ui/react";
import { MailValue } from "core";
import {
  collection,
  CollectionReference,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React from "react";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import TableCard from "../../../../components/TableCard";
import { WithId } from "../../../../types/utils";
import MailRow, { columnNames as mailColumnNames } from "../MailRow";

interface Props {
  email: string;
}
export default function EmailTab({ email }: Props) {
  const firestore = useFirestore();
  const mailQuery = query(
    collection(firestore, "mail") as CollectionReference<
      WithId<MailValue<any>>
    >,
    where("to", "==", email),
    orderBy("delivery.startTime", "desc")
  );
  const { data: mails = [] } = useFirestoreCollectionData(mailQuery, {
    idField: "id",
  });

  return (
    <Box>
      <TableCard columnNames={mailColumnNames} title="Mail">
        {mails.map((mail) => (
          <MailRow
            mail={mail}
            isLast={mail.id === mails[mails.length - 1].id}
          />
        ))}
      </TableCard>
    </Box>
  );
}
