export function formatDate(date: Date | undefined) {
  if (!date) return "";
  let formattedDate = date.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
  });
  return formattedDate.substring(0, formattedDate.lastIndexOf(" "));
}
