import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { RunValue } from "core";
import React from "react";
import { Link } from "react-router-dom";
import { WithId } from "../../../types/utils";

interface Props {
  run: WithId<RunValue>;
  isLast?: boolean;
  handleApproveButtonClick: () => void;
}

export const columnNames = [
  "ID",
  "Created",
  "API Requests",
  "Assessments",
  "Batch Size",
  "Cost",
  "Actions",
];

export default function RunRow({
  handleApproveButtonClick,
  run,
  isLast,
}: Props) {
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Link to={`/communities/${run.communityId}/runs/${run.id}`}>
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {run.id}
            </Text>
          </Link>
        </Flex>
      </Td>
      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {run.createdAt.toDate().toDateString()}
        </Text>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {run.assessmentRequestCount}
          </Text>
        </Flex>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {run.assessmentCount}
          </Text>
        </Flex>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {run.batchSize}
          </Text>
        </Flex>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex
          dir="column"
          align="center"
          py=".8rem"
          minWidth="100%"
          flexWrap="nowrap"
        >
          {run.estimatedApiCostCents ? (
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              ${Math.round(run.estimatedApiCostCents) / 100} (est)
            </Text>
          ) : null}
          {run.actualApiCostCents ? (
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              ${Math.round(run.actualApiCostCents) / 100} (actual)
            </Text>
          ) : null}
        </Flex>
      </Td>
      <Td
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        {run.approvedAt ? null : (
          <Button onClick={handleApproveButtonClick}>Approve</Button>
        )}
      </Td>
    </Tr>
  );
}
