import { QuestionIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Popover,
  Radio,
  RadioGroup,
  Stack,
  Tooltip,
} from "@chakra-ui/react";
import { ResponseValue, RunValue } from "core";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  Query,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import humanizeString from "humanize-string";
import React, { ComponentProps, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import TableCard from "../../../components/TableCard";
import { WithId } from "../../../types/utils";
import ResponseRow, { columnNames as responseColumnNames } from "./ResponseRow";
import RunRow, { columnNames as runColumnNames } from "./RunRow";

type Mode = "all" | "pending";
const modes = ["all", "pending", "finalized"] as Mode[];

type T = ComponentProps<typeof Popover>;

export default function CommunityIndex() {
  let location = useLocation();
  let { id } = useParams();

  const isRoot = location.pathname.endsWith(id!);

  if (isRoot) return <Community />;
  return <Outlet />;
}

function Community() {
  let { id } = useParams();

  const firestore = useFirestore();
  const document = doc(collection(firestore, "communities"), id);
  const { data, status } = useFirestoreDocData(document);
  const [mode, setMode] = useState<Mode>("all");

  const runsQuery = query(
    collection(firestore, "runs"),
    where("communityId", "==", id)
  ) as Query<RunValue>;
  const { data: runs = [] } = useFirestoreCollectionData(
    runsQuery as Query<WithId<RunValue>>,
    { idField: "id" }
  );
  const responsesQuery = getResponsesQuery(firestore, id!, mode);
  const { data: responses = [] } = useFirestoreCollectionData(
    responsesQuery as Query<WithId<ResponseValue>>,
    { idField: "id" }
  );

  function handleApproveButtonClick(runId: string) {
    const run = doc(collection(firestore, "runs"), runId);
    updateDoc(run, { approvedAt: Timestamp.now() });
  }

  function onCreateRunButtonPress() {
    addDoc(collection(firestore, "runs"), {
      batchSize: 6,
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
      communityId: id,
    });
  }

  if (status === "loading") return <div>Loading...</div>;

  return (
    <div>
      <Heading mb={4} size="lg">
        {data.name}
      </Heading>

      {/* Uncomment to show redash reports when enabled */}
      {/* <div
        style={{
          height: "85vh",
          width: "100%",
          marginBottom: 30,
          overflow: "hidden",
        }}
      >
        <iframe
          // Hack to hide some Redash dashboard shit that we don't want
          style={{
            width: "100%",
            height: "100%",
            marginTop: -158,
            overflow: "hidden",
          }}
          scrolling="no"
          src={
            "https://redash.intronet.ai/public/dashboards/lklsiuzBGo9PX8CpcsR4PW3RGWyFKnTFLsEF0QbH?org_slug=default&p_community_id=" +
            id
          }
        />
      </div> */}

      <TableCard
        columnNames={runColumnNames}
        title="Runs"
        action={
          <Flex justifyContent="flex-end">
            <Tooltip label="Clicking this button will run the matching with OpenAI. It costs money, and runs every hour. After approving the run, check back later :)">
              <IconButton
                variant="ghost"
                aria-label="info"
                icon={<QuestionIcon />}
                mr={2}
              />
            </Tooltip>

            <Button onClick={onCreateRunButtonPress}>Create a run</Button>
          </Flex>
        }
      >
        {runs.map((run) => (
          <RunRow
            run={run}
            handleApproveButtonClick={() => handleApproveButtonClick(run.id)}
          />
        ))}
      </TableCard>
      <Divider my={8} />

      <TableCard
        columnNames={responseColumnNames}
        title="Responses"
        controls={
          <RadioGroup
            mb={4}
            onChange={(m) => setMode(m as typeof mode)}
            value={mode}
          >
            <Stack direction="row">
              {modes.map((m) => (
                <Radio value={m}>{humanizeString(m)}</Radio>
              ))}
            </Stack>
          </RadioGroup>
        }
      >
        {responses.map((response) => (
          <ResponseRow response={response} />
        ))}
      </TableCard>
    </div>
  );
}

function getResponsesQuery(
  firestore: Firestore,
  communityId: string,
  mode: Mode
) {
  if (mode === "pending") {
    return query(
      collection(firestore, "responses"),
      where("communityId", "==", communityId),
      where("finalizedAt", "==", null)
    );
  } else if (mode === "all") {
    return query(
      collection(firestore, "responses"),
      where("communityId", "==", communityId)
    ) as Query<RunValue>;
  } else if (mode === "finalized") {
    return query(
      collection(firestore, "responses"),
      where("communityId", "==", communityId),
      where("finalizedAt", "!=", null)
    ) as Query<RunValue>;
  }
}
