import { Box } from "@chakra-ui/react";
import { MailValue } from "core";
import { collection, CollectionReference, doc } from "firebase/firestore";
import React from "react";
import { Outlet, useParams } from "react-router-dom";
import { useFirestore, useFirestoreDocDataOnce } from "reactfire";
import { WithId } from "../../../types/utils";

export function Mails() {
  return <Outlet />;
}

export function Mail() {
  const { id } = useParams();
  const firestore = useFirestore();
  const { data } = useFirestoreDocDataOnce(
    doc(
      collection(firestore, "mail") as CollectionReference<
        WithId<MailValue<any>>
      >,
      id
    )
  );

  return (
    <Box>
      <iframe style={{ width: "100%", height: "90vh" }} srcDoc={data?.html} />
    </Box>
  );
}
