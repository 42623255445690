import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/system";
import React from "react";
import { NavLink } from "react-router-dom";
import IconBox from "../Icons/IconBox";

interface Props {
  route: {
    redirect?: boolean;
    category?: string;
    label: string;
    children?: any;
    path: string;
    icon?: any;
  };
  isActive: boolean;
  variantChange?: string;
}
export default function SidebarLink({ isActive, variantChange, route }: Props) {
  const activeBg = useColorModeValue("white", "navy.700");
  const inactiveBg = useColorModeValue("white", "navy.700");
  const activeColor = useColorModeValue("gray.700", "white");
  const inactiveColor = useColorModeValue("gray.400", "gray.400");
  const sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.04)";
  const { redirect, category, label, path, icon } = route;

  if (redirect) {
    return null;
  }
  if (category) {
    return (
      <>
        <Text
          color={isActive ? activeColor : inactiveColor}
          fontWeight="bold"
          mb={{
            xl: "6px",
          }}
          mx="auto"
          ps={{
            sm: "10px",
            xl: "16px",
          }}
          py="12px"
        >
          {label}
        </Text>
        {/* {createLinks(views)} */}
      </>
    );
  }
  return (
    <NavLink className={"sidebar-link"} to={path}>
      <Button
        boxSize="initial"
        justifyContent="flex-start"
        alignItems="center"
        boxShadow={sidebarActiveShadow}
        bg={isActive ? activeBg : inactiveBg}
        transition={variantChange}
        mb={{
          xl: "6px",
        }}
        mx={{
          xl: "auto",
        }}
        ps={{
          sm: "10px",
          xl: "16px",
        }}
        py="12px"
        borderRadius="15px"
        _hover={"none" as any}
        w="100%"
        _active={{
          bg: "inherit",
          transform: "none",
          borderColor: "transparent",
        }}
        _focus={{
          boxShadow: "0px 7px 11px rgba(0, 0, 0, 0.04)",
        }}
      >
        <Flex>
          {typeof icon === "string" ? (
            <Icon>{icon}</Icon>
          ) : (
            <IconBox
              bg="blue.500"
              color="white"
              h="30px"
              w="30px"
              me="12px"
              transition={variantChange}
            >
              {icon}
            </IconBox>
          )}
          <Text color={activeColor} my="auto" fontSize="sm">
            {label}
          </Text>
        </Flex>
      </Button>
    </NavLink>
  );
}
