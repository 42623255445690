import {
  Avatar,
  Badge,
  Button,
  Flex,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { AskValue, AssessmentValue, ResponseValue } from "core";
import {
  collection,
  CollectionReference,
  doc,
  orderBy,
  Query,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { keyBy } from "lodash";
import React from "react";
import { FaHandSparkles, FaLinkedin } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFirestoreDocData,
} from "reactfire";
import TableCard from "../../../components/TableCard";
import { WithId } from "../../../types/utils";
import { formatDate } from "../../../utils/dates";
import AssessmentsTab from "./tabs/Assessments";
import EmailTab from "./tabs/Email";
import ResponseTab from "./tabs/Response";

export default function Response() {
  let { id, rId } = useParams();
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,.8)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const firestore = useFirestore();
  const document = doc(
    collection(firestore, "responses") as CollectionReference<ResponseValue>,
    rId
  );
  const { data: response, status } = useFirestoreDocData(document);

  const assessmentsQuery = query(
    collection(firestore, "assessments"),
    where("seekerResponseId", "==", rId),
    orderBy("score", "desc")
  ) as Query<AssessmentValue>;

  const { data: assessments = [] } = useFirestoreCollectionData(
    assessmentsQuery as Query<WithId<AssessmentValue>>,
    { idField: "id" }
  );

  const asksQuery = query(
    collection(firestore, "asks"),
    where("responseId", "==", rId)
  ) as Query<AskValue>;

  const { data: asks = [] } = useFirestoreCollectionData(
    asksQuery as Query<WithId<AskValue>>,
    { idField: "id" }
  );

  const responsesQuery = query(
    collection(firestore, "responses"),
    where("communityId", "==", id)
  ) as Query<ResponseValue>;

  const { data: responses = [] } = useFirestoreCollectionData(
    responsesQuery as Query<WithId<ResponseValue>>,
    { idField: "id" }
  );
  const responsesById = keyBy(responses, "id");

  function handleSendSummaryEmailButtonClick() {
    updateDoc(document, { finalizedAt: Timestamp.now() });
  }

  const selectedAssessments = assessments.filter((a) => a.isSelected);

  if (status === "loading") return <div>Loading...</div>;

  return (
    <div>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb="24px"
        maxH="330px"
        justifyContent={{ sm: "center" }}
        align="center"
        backdropFilter="blur(21px)"
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={bgProfile}
        p="24px"
        borderRadius="20px"
      >
        <Flex
          align="center"
          mb={{ sm: "10px", md: "0px" }}
          direction={{ sm: "column", md: "row" }}
          w={{ sm: "100%", md: "auto" }}
          textAlign={{ sm: "center", md: "start" }}
        >
          <Avatar
            src={response.linkedinProfile?.profile_pic_url}
            name={response.name}
            w="50px"
            borderRadius="12px"
            me="18px"
          />
          <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
            <Text
              fontSize={{ sm: "lg", lg: "xl" }}
              color={textColor}
              fontWeight="bold"
              ms={{ sm: "8px", md: "0px" }}
            >
              {response.name}
            </Text>
            <Text
              fontSize={{ sm: "sm", md: "md" }}
              color={emailColor}
              fontWeight="semibold"
            >
              {response.email}
            </Text>
          </Flex>
        </Flex>
        <Flex opacity={0.5} ml={4} alignItems="center" flexGrow={1} p="16px">
          {response.linkedinProfile ? (
            <a
              href={
                "https://linkedin.com/in/" +
                response.linkedinProfile?.public_identifier
              }
              target="_blank"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Icon as={FaLinkedin} size="lg" mr={2} />
              {response.linkedinProfile?.headline ||
                response.linkedinProfile?.occupation}
            </a>
          ) : null}
        </Flex>
        <Flex
          direction={{ sm: "column", lg: "row" }}
          w={{ sm: "100%", md: "50%", lg: "auto" }}
        >
          {response.finalizedAt ? (
            <Badge>
              {"Finalized at " + formatDate(response.finalizedAt.toDate())}
            </Badge>
          ) : (
            <Button
              boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.06)"
              size="lg"
              w={{ sm: "100%" }}
              leftIcon={<Icon as={FaHandSparkles} me="6px" />}
              colorScheme="yellow"
              color="white"
              disabled={!selectedAssessments.length}
              onClick={handleSendSummaryEmailButtonClick}
            >
              Send summary email
            </Button>
          )}
        </Flex>
      </Flex>

      <TableCard
        mb={4}
        columnNames={["Can help with", "Help wanted"]}
        title="Response"
      >
        <Tr>
          <Td minWidth={{ sm: "200px" }} pl="0px" borderColor={borderColor}>
            {response.canOffer}
          </Td>
          <Td minWidth={{ sm: "200px" }} pl="0px" borderColor={borderColor}>
            {response.helpWanted}
          </Td>
        </Tr>
      </TableCard>

      <Tabs>
        <TabList>
          <Tab>Response</Tab>
          <Tab>Assessments</Tab>
          <Tab>Mail</Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <ResponseTab response={response} asks={asks} />
          </TabPanel>
          <TabPanel p={0}>
            <AssessmentsTab
              assessments={assessments}
              responsesById={responsesById}
              isActionDisabled={!!response.finalizedAt}
            />
          </TabPanel>
          <TabPanel p={0}>
            <EmailTab email={response.email} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}
