import {
  Button,
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { AssessmentValue, ResponseValue } from "core";
import { collection, doc, updateDoc } from "firebase/firestore";
import React from "react";
import { Link } from "react-router-dom";
import { useFirestore } from "reactfire";
import { WithId } from "../../../types/utils";

interface Props {
  assessment: WithId<AssessmentValue>;
  providerResponse?: WithId<ResponseValue>;
  seekerResponse?: ResponseValue;
  isLast?: boolean;
  isActionDisabled?: boolean;
}

export const columnNames = [
  "Ask",
  "Name",
  "Score",
  "Human Score",
  "Explanation",
  "",
];

export default function AssessmentRow({
  assessment,
  providerResponse,
  isLast,
  isActionDisabled,
}: Props) {
  const firestore = useFirestore();
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const assessmentDoc = doc(
    collection(firestore, "assessments"),
    assessment.id
  );

  function handleToggleSelectedButtonClick() {
    updateDoc(assessmentDoc, { isSelected: !assessment.isSelected });
  }

  function handleEditExplanationButtonClick() {
    const explanation = prompt("Update explanation", assessment.explanation);
    if (!explanation) return;

    updateDoc(assessmentDoc, { explanation });
  }

  function handleEditHumanScoreButtonClick() {
    const scoreString = prompt(
      "Update human score",
      assessment.humanScore?.toString() || ""
    );
    if (scoreString === "") return;

    const humanScore = Number(scoreString);

    updateDoc(assessmentDoc, { humanScore });
  }

  return (
    <Tr sx={{ backgroundColor: assessment.isSelected ? "#dadada" : undefined }}>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex
          direction={"column"}
          align="center"
          py=".8rem"
          minWidth="100%"
          flexWrap="nowrap"
        >
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {assessment.id}
          </Text>
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {assessment.askSummary}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Link
            to={`/communities/${providerResponse?.communityId}/responses/${providerResponse?.id}`}
          >
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {providerResponse?.name}
            </Text>
          </Link>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {assessment.score}
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {assessment.humanScore}
          </Text>
          <a
            href="javascript:void(0)"
            style={{ color: "blue" }}
            onClick={handleEditHumanScoreButtonClick}
          >
            Edit
          </a>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {assessment.explanation}
            <a
              href="javascript:void(0)"
              style={{ color: "blue" }}
              onClick={handleEditExplanationButtonClick}
            >
              Edit
            </a>
          </Text>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Button
          disabled={isActionDisabled}
          onClick={handleToggleSelectedButtonClick}
        >
          {assessment.isSelected ? "Unselect" : "Select"}
        </Button>
      </Td>
    </Tr>
  );
}
