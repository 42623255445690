/*eslint-disable*/
import { Flex, Text } from "@chakra-ui/react";
import React from "react";

export default function Footer(props: any) {
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="flex-end"
      px="30px"
      pb="20px"
    >
      <Text
        color="gray.400"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        <Text as="span">
          Intronet: Putting the "LOL" in "COLLABORATE" since 2023 🚀
        </Text>
      </Text>
    </Flex>
  );
}
