import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertStatus,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import React, { createContext } from "react";
import { v4 as uuidv4 } from "uuid";

interface Props {
  children: React.ReactNode;
}
interface Message {
  id: string;
  type: AlertStatus;
  message: string;
  description?: string;
}
interface ContextType {
  showMessage: (message: Omit<Message, "id">) => void;
}
export const FlashContext = createContext<ContextType>(null as any);

export default function WithFlashProvider({ children }: Props) {
  const [messages, setMessages] = React.useState<Message[]>([]);

  const showMessage: ContextType["showMessage"] = (message) => {
    const messageId = uuidv4();
    setMessages((m) => [...m, { ...message, id: messageId }]);
    setTimeout(() => hideMessage(messageId), 5000);
  };

  function hideMessage(messageId: string) {
    setMessages((m) => m.filter((message) => message.id !== messageId));
  }

  return (
    <FlashContext.Provider value={{ showMessage }}>
      {children}
      <Box width={"100%"} position={"absolute"}>
        {messages.map(({ type, message, description }) => (
          <Alert status={type}>
            <AlertIcon />
            <AlertTitle>{message}</AlertTitle>
            {description ? (
              <AlertDescription>{description}</AlertDescription>
            ) : null}
          </Alert>
        ))}
      </Box>
    </FlashContext.Provider>
  );
}
