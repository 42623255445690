import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";
import { FirebaseAppProvider } from "reactfire";
import App from "./App";
import firebaseProdConfig from "./firebase.prod.config";
import firebaseTestConfig from "./firebase.test.config";
import theme from "./theme/theme";

const config =
  process.env.REACT_APP_ENV === "production"
    ? firebaseProdConfig
    : firebaseTestConfig;

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={config}>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </FirebaseAppProvider>,
  document.getElementById("root")
);
