import { Flex, Td, Text, Tr, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface Props {
  community: any;
  isLast?: boolean;
}

export const columnNames = ["ID", "Name", "Responses", "Created"];

export default function CommunityRow({ community, isLast }: Props) {
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Tr>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Link to={`/communities/${community.id}`}>
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {community.id}
            </Text>
          </Link>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Link to={`/communities/${community.id}`}>
            <Text fontSize="sm" color="gray.700" fontWeight="normal">
              {community.name}
            </Text>
          </Link>
        </Flex>
      </Td>
      <Td
        minWidth={{ sm: "200px" }}
        pl="0px"
        borderColor={borderColor}
        borderBottom={isLast ? "none" : undefined}
      >
        <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
          <Text fontSize="sm" color="gray.700" fontWeight="normal">
            {community.responseCount}
          </Text>
        </Flex>
      </Td>

      <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
        pl="0px"
      >
        <Text fontSize="md" color={textColor} fontWeight="bold" pb=".5rem">
          {community.createdAt.toDate().toDateString()}
        </Text>
      </Td>
      {/* <Td
        borderColor={borderColor}
        borderBottom={isLast ? "none" : (null as any)}
      >
        <Button p="0px" bg="transparent" variant="no-effects">
          <Text
            fontSize="md"
            color="gray.700"
            fontWeight="bold"
            cursor="pointer"
          >
            Edit
          </Text>
        </Button>
      </Td> */}
    </Tr>
  );
}
