import { Box, Td, Tr } from "@chakra-ui/react";
import { AskValue, ResponseValue } from "core";

import React from "react";
import TableCard from "../../../../components/TableCard";
import { WithId } from "../../../../types/utils";
import AskRow, { columnNames as askColumnNames } from "../AskRow";

interface Props {
  response: ResponseValue;
  asks: WithId<AskValue>[];
}

export default function ResponseTab({ response, asks }: Props) {
  return (
    <Box>
      <TableCard mb={4} columnNames={askColumnNames} title="Asks">
        {asks.map((ask) => (
          <AskRow ask={ask} isLast={ask.id === asks[asks.length - 1].id} />
        ))}
      </TableCard>

      {response.linkedinProfile ? (
        <TableCard
          mb={4}
          columnNames={["Field", "Value"]}
          title="LinkedIn data"
        >
          <Tr>
            <Td>Headline</Td>
            <Td>{response.linkedinProfile?.headline}</Td>
          </Tr>
          <Tr>
            <Td>Certifications</Td>
            <Td>
              {response.linkedinProfile?.certifications?.map(
                ({ name }) => name
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Experiences</Td>
            <Td>
              {response.linkedinProfile?.experiences?.map(
                ({ title, company }) => (
                  <p>{JSON.stringify({ title, company })}</p>
                )
              )}
            </Td>
          </Tr>

          <Tr>
            <Td>Languages</Td>
            <Td>{response.linkedinProfile?.languages}</Td>
          </Tr>
          <Tr>
            <Td>Volunteer work</Td>
            <Td>
              {response.linkedinProfile?.volunteer_work?.map(
                ({ title, description, company }) => (
                  <p>{JSON.stringify({ title, description, company })}</p>
                )
              )}
            </Td>
          </Tr>
          <Tr>
            <Td>Education</Td>
            <Td>
              {response.linkedinProfile?.education?.map(
                ({ degree_name, field_of_study, school }) => (
                  <p>
                    {JSON.stringify({
                      degree_name,
                      field_of_study,
                      school,
                    })}
                  </p>
                )
              )}
            </Td>
          </Tr>
        </TableCard>
      ) : null}
    </Box>
  );
}
